const themeConfig: ThemeConfig.Config = {

  // primary: '#00c88c',
  primary: '#' + import.meta.env.VITE_PRIMARY_COLOR,
  followOs: false,
  draw: true,
  globalTheme: 'light',
  menuTheme: 'light',
  toolbarTheme: 'light',
  isToolbarDetached: true,
  isContentBoxed: true,
  isRTL: false,
  light: {
    dark: false,
    variables: {
      "high-emphasis-opacity": 1,
      "border-opacity": 0.05,
    },
    colors: {
        //primary: '#00c88c',
      // secondary: '#66add8',
      background: '#' + import.meta.env.VITE_BACKGROUND_COLOR,
      // background: '##DAF2FF',
      surface: '#ffffff',
      primary: '#' + import.meta.env.VITE_PRIMARY_COLOR,
      secondary: '#' + import.meta.env.VITE_SECONDARY_COLOR,
      accent: '#E53935',
      error: '#E53935',
      info: '#2196F3',
      success: '#43A047',
      "on-success": '#ffffff',
      warning: '#ffd166',
    }
  }
}

export default themeConfig
